import React from 'react';
import MainNav from '../Components/MainNav.js';
import paymentSuccess from '../../assets/img/logo/uttarakhanda.png';
import { Link, useParams } from 'react-router-dom';
import { useUserProfile, useTournamentDetails } from 'profiles/queries/profileHooks.js';
import { useMutateCoachVerificationRequest } from 'registration/queries/mutations.js';

export default function RegistrationSuccess() {
  const useProfileQueryData = useUserProfile();
  const params = useParams();
  const tournamentId = params.tournament_id;
  const tournamentDetails = useTournamentDetails({
    tournamentId,
  });
  // console.log(tournamentDetails);
  const submitSportEvents = async () => {
    try {
      const values = {
        tournamentId: tournamentId,
        sportEvents: [],
      };
      await submitSportEventsAsync(values);
    } catch (e) {
      console.log(e);
    }
  };

  const { mutateAsync: submitSportEventsAsync } = useMutateCoachVerificationRequest({
    nError: () => {},
    onSuccess: () => {},
  });

  React.useEffect(() => {
    submitSportEvents();
  }, []);
  return (
    <>
      <MainNav />
      <div className="px-4">
        <div
          className="relative bg-white text-gray-700 px-2 md:px-16 py-8 rounded-lg my-8 w-full md:w-10/12 font-roboto m-auto flex flex-col items-center justify-around text-base payment-success border border-gray-200"
          style={{ marginBottom: '250px' }}
        >
          <img
            src={paymentSuccess}
            alt="Payment Success"
            className="rounded-full bg-blue-950 p-2 w-32 h-32 md:w-44 md:h-44"
          />
          <h2 className="mt-4 text-lg mx-1 md:mx-0 text-center">{`Hi ${useProfileQueryData?.data?.message?.full_name}`}</h2>
          <h1 className="text-2xl font-bold my-1">Registration Successful</h1>
          <p className="w-4/5 md:w-7/12 text-sm mt-4 mx-auto text-center font-bold">
            {useProfileQueryData?.data?.message?.primary_role !== 'coach'
              ? `Thank you for registering for ${
                  (tournamentDetails.isFetched && tournamentDetails?.data?.name) ||
                  'SFA Championship Uttarakhand 2022'
                }.`
              : null}
            <div>Kindly Note Your Verification Status Will Be Confirmed Within 3 Days.</div>
            <div>
              Upon successful verification you shall be able to generate team registration links.
            </div>
          </p>

          <Link
            className="mt-4 font-bold text-gray-750 border-gray-750 border-1 rounded-lg px-6 py-2"
            to="/"
          >
            Go to Profile
          </Link>
        </div>
      </div>
    </>
  );
}
